<template>
  <div div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="toggle(false)">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.parent }}
          <span> > </span>
          {{ $route.meta.title }}
          <span> > </span>
          {{currStatus == 0 ? '创建机构' : (currStatus == 1 ? '审核机构' : '查看机构')}}
        </div>
      </div>
    </el-header>

    <div class="form-wrap">
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='110px'
        label-position='top'
        :model='form.data'
        :rules='form.rules'
        size="small"
        hide-required-asterisk
      >
        
        <el-row class="licence_wrap">
          <div class="check_remark" v-if="!!form.data.check_remark">
            <el-tooltip class="item" effect="dark" :content="form.data.check_remark" placement="bottom">
              <el-button  plain icon="el-icon-warning-outline" size="mini" >查看拒绝原因</el-button>
            </el-tooltip>
          </div>
          <el-col :span="6" style="width:200px;">
            <div class="label" style="margin-bottom:10px">营业执照</div>
            <el-image 
              v-if="isChange"
              style="width: 160px; height: 160px"
              :src="form.data.business_license" 
              :preview-src-list="[form.data.business_license]">
            </el-image>
            <el-upload
              v-else
              class="upload-demo"
              action=""
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-remove="handleRemove"
              :http-request="httpRequest"
              accept="image/jpeg, image/png, image/gif">
              <img v-if="form.data.business_license" :src="form.data.business_license" style="width:160px;height:160px;border-radius:6px;">
              <div v-else style="position: relative;">
                <img style="160px;height:160px;object-fit: cover;" :src="require('@/assets/img/upload2.png')" />
                <span class="imgtip">请先上传清晰且未遮挡的营业执照图片</span>
              </div>
              <div  slot="tip" class="el-upload__tip imghint">支持jpg/jpeg/png格式文件 大小不超过5M</div>
            </el-upload>
          </el-col>
          <el-col :span="14">
            <el-row :gutter="30">
              <el-col :span="24">
                <el-row :gutter="30">
                  <el-col :span="12">
                    <el-form-item label="" prop="name">
                      <span slot="label" class="label">机构名称</span>
                      <el-input
                        v-model="form.data.name"
                        placeholder="自动获取营业执照信息"
                        :disabled="true">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-form-item label="" prop="legal_person">
                  <span slot="label" class="label">法人姓名</span>
                  <el-input
                    v-model="form.data.legal_person"
                    placeholder="自动获取营业执照信息"
                    :disabled="true">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="" prop="organization_code">
                  <span slot="label" class="label">组织机构代码</span>
                  <el-input
                    v-model="form.data.organization_code"
                    placeholder="自动获取营业执照信息"
                    :disabled="true">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="" prop="area_arr">
                  <span slot="label" class="label">注册地址</span>
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <el-cascader
                        style="width:100%;"
                        :popper-append-to-body="false"
                        popper-class="areaCascaderWrap"
                        v-model="form.data.area_arr"
                        :options="areaArr"
                        :props="{ checkStrictly: false }"
                        :disabled="isChange"
                        >
                      </el-cascader>
                    </el-col>
                    <el-col :span="12">
                      <el-input
                        v-model="form.data.address"
                        placeholder="自动获取营业执照信息"
                        :disabled="true"
                        type="textarea"
                        :autosize="{ minRows: 1, maxRows: 2}">
                      </el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="7" >
            <el-form-item label="机构简称" prop="simple_name">
              <el-input
                v-model="form.data.simple_name"
                placeholder="请输入机构简称，用于展示在产品详情中"
                clearable
                :disabled="isChange">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="行业类型" prop="industry">
              <el-input
                v-model="form.data.industry"
                placeholder="请输入所属的行业类型"
                clearable
                :disabled="isChange">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="机构规模（人）" prop="max_contain_count">
              <el-input
                v-model="form.data.max_contain_count"
                placeholder="请输入最大容纳人数"
                @input="form.data.max_contain_count=form.data.max_contain_count.replace(/[^\d^\.]/g,'')"
                clearable
                :disabled="isChange"
                >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="联系人" prop="contact">
              <el-input
                v-model="form.data.contact"
                placeholder="请输入联系人"
                clearable
                :disabled="isChange">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="联系电话" prop="contact_mobile">
              <el-input
                v-model="form.data.contact_mobile"
                maxlength="11"
                placeholder="请输入联系人手机号码"
                clearable
                :disabled="isChange">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="机构简介" prop="description">
              <el-input
                v-model="form.data.description"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8}"
                placeholder="请输入机构简介"
                clearable
                maxlength="500"
                show-word-limit
                :disabled="isChange">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="场地照片" prop="space_url" class="imageWrap">
              <template slot="label">场地照片</template>
              <span class="label-hint">（至少上传1张，限9张）</span>
              <el-upload
                action=""
                :limit="9"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :file-list="fileList"
                :http-request="httpRequestBack"
                accept="image/jpeg, image/png, image/gif"
                :disabled="isChange">
                <img style="104px;height:104px;object-fit: cover;" :src="require('@/assets/img/upload.png')" />
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" append-to-body>
                <template #title>
                  <div class="el-dialog-title">查看图片</div>
                </template>
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 创建 -->
    <div class="licence_foot" v-if="currStatus == 0">
      <el-button  @click="toggle(false)">取消</el-button>
      <el-button type="primary" @click="submitForm">提交</el-button>
    </div>
    <!-- 审核 -->
    <div class="licence_foot" v-if="currStatus == 1">
      <el-button  @click="handleVerify(2)">审核不通过</el-button>
      <el-button type="primary" @click="handleVerify(1)">审核通过</el-button>
    </div>
    
   
  </div>
</template>


<script>
import MyEditor from "@/components/MyEditor.vue";
import { REG_EXP } from '@/util'
export default {
  name: 'OrganEdit',
  components: {
    MyEditor
  },
  data() {
    return {
      loading: false,
      visible: false,
      isChange: false,
      form: {
        data: {
          business_license: '', //营业执照
          name: '',//机构名称
          legal_person: '',//法人姓名
          organization_code: '',//组织机构代码
          area_arr: [],
          province: 0, //注册地址省
          city: 0, //注册地址市
          area: 0, //注册地址区
          address: "", //地址
          simple_name: "", //机构简称
          industry: "", //行业类型
          max_contain_count: "", //最大容纳人数
          contact: "", //联系人
          contact_mobile: "", //电话
          description: "", //机构简介
          space_url: "", //场地照片，用,分割
        },
        rules: {
          business_license: [{ required: true, message:'必填项', trigger: 'change' }],
          name: [{ required: true, message:'必填项', trigger: 'change' }],
          legal_person: [{ required: true, message:'必填项', trigger: 'change' }],
          organization_code: [{ required: true, message:'必填项', trigger: 'change' }],
          area_arr: [{ required: true, message:'必填项', trigger: 'change' }],
          address: [{ required: true, message:'必填项', trigger: 'change' }],
          simple_name: [{ required: true, message:'必填项', trigger: 'change' }],
          industry: [{ required: true, message:'必填项', trigger: 'change' }],
          max_contain_count: [{ required: true, message:'必填项', trigger: 'change' }],
          contact: [{ required: true, message:'必填项', trigger: 'change' }],
          contact_mobile: [
            { required: true, message:'必填项', trigger: 'change' },
            { pattern: REG_EXP.mobilePhone, message: "手机号不正确", trigger: 'blur' }
          ],
          description: [{ required: true, message:'必填项', trigger: 'blur' }],
          space_url: [{ required: true, message:'必填项', trigger: 'change' }],
        },
      },
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: [],
      areaArr: [],
      currStatus: 0, // 0:创建，1：审核，2或3：查看
    }
  },
  created() {
    this.getArea();
  },
  methods: {
    // 详情
    getDetail(row, status) {
      if(!!row.id) {
        this.currStatus = status; 
        this.isChange = true;
        this.$http.get('/admin/company/info', {params:{id:row.id,status:this.currStatus}}).then(res => {
          this.form.data = res.data;
          this.form.data.area_arr = [res.data.province,res.data.city,res.data.area];
          var _space_url = this.form.data.space_url
          if(_space_url.length > 0) {
            this.fileList = []
            _space_url.forEach(v => {
              let str = {
                name: '',
                url: v
              }
              this.fileList.push(str)
            })
          }
        })
      }
    },
    reset(done) {
      this.isChange = false
      this.$refs.elFormDom.resetFields()
      this.fileList = [];
      this.form.data.business_license = '';
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    // 获取省市区
    getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
    async httpRequest(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.loading = true;
      await this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
        this.form.data.business_license = res.data.all_path_url;
        this.$refs.elFormDom.clearValidate();
      }).finally(() => {
        this.loading = false;
      })
      // 识别营业执照
      await this.$http.post('/common/baidu/license', {url: this.form.data.business_license}).then(res => {
        console.log(res.data)
        this.form.data.name = res.data.name;
        this.form.data.legal_person = res.data.legal;
        this.form.data.organization_code = res.data.credit_code;
        this.form.data.address = res.data.address;
      }).finally(() => {
        this.loading = false;
      })
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 5MB!');
      }
      return isLt5M;
    },
    
    // 详情图片上传
    httpRequestBack(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
        if(res.code === 1) {
          let str = {
            url: res.data.all_path_url,
            name: file.file.name,
            uid: file.file.uid
          }
          this.fileList.push(str);
          this.form.data.space_url = res.data.all_path_url;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file) {
      this.fileList.forEach((v,i) => {
        if(file.uid == v.uid) {
          this.fileList.splice(i, 1)
        }
      })
    },
    submitForm() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          let _params = {...this.form.data}
          if(this.fileList.length > 0){
            let _image = []
            this.fileList.forEach(v => {
              _image.push(v.url)
            })
            _params.space_url = _image.join(",")
          } else {
            _params.space_url = '';
          }
          _params.province = this.form.data.area_arr[0];
          _params.city = this.form.data.area_arr[1];
          _params.area = this.form.data.area_arr[2];
          delete _params.area_arr;
          this.$http.post('/admin/company/add', _params).then(res => {
            if(res.code == 1) {
              this.$message.success('提交成功！')
              this.$emit('refresh')
              this.toggle(false)
            }
          })
        }
      })
    },
    // 审核，1：通过，2不通过
    handleVerify(type) {
      if(type == 1) {
        this.$confirm('确定审核通过吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let _params = {
            id: this.form.data.id,
            status: 1, // 通过
          }
          this.verifyFun(_params)
        }).catch(() => {});
      } else {
        this.$prompt('审核不通过', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPlaceholder: '请输入不通过理由',
          inputPattern: /^.+$/,
          inputErrorMessage:'请输入不通过理由'
        }).then(({ value }) => {
          let _params = {
            id: this.form.data.id,
            status: 2, // 不通过
            check_remark: value
          }
          this.verifyFun(_params)
        }).catch(() => {});
      }
    },
    verifyFun(_params) {
      this.$http.post('/admin/company/check',_params).then(res => {
        if(res.code == 1) {
          this.$message.success("操作成功！")
          this.$emit("refresh")
          this.toggle(false)
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #F1F4FC;
  z-index: 5 !important;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}
.page-header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  color: #595959;

  .title {
    height: 58px;
    line-height: 58px;
  }

  .site {
    color: #898989;
  }
  
  .backBtn {
    height: 30px;
    border: solid 1px #007DF2;
    line-height: 30px;
    padding: 0 9px;
    border-radius: 3px;
    background: #E5F2FE;
    color: #007DF2;
    cursor: pointer;
    margin: 15px 20px 15px 0;
  }
  .back-img {
    width: 18px;
    height: 16px;
    position: relative;
    top: 3px;
    margin-right: 10px;
  }
}
.saft_form {
  padding: 20px;
}
.form-wrap {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  margin: 20px;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}
.itemTime {
  display: block;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #409EFF;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}



</style>
<style scoped>
.imageWrap ::v-deep .el-upload--picture-card {
  border: none;
  width: 104px;
  height: 104px;
}
.imageWrap ::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 104px;
  height: 104px;
}
.imageWrap ::v-deep .el-range-editor--small .el-range-separator {
  width: 20px;
}
.licence_foot {
  background: #fff;
  padding: 5px 20px;
  margin-top: auto;
  text-align: right;
}
.licence_wrap {
  background: #FCFCFC;
  border: solid 1px #E5E5E5;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 10px;
  position: relative;
}
.check_remark {
  position:absolute;
  top: 10px;
  right:10px;
}
.licence_foot {
  background: #fff;
  padding: 10px 20px;
  margin-top: auto;
  text-align: right;
}
.space-between {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
}
.justify-between {
  justify-content: space-between;
}
.imghint {
  width: 160px;
  font-size: 12px;
  color: #8C8C8C;
  text-align: center;
  line-height: 20px;
}
.imgtip {
  font-size: 12px;
  color: rgb(243, 81, 73);
  bottom: 26px;
  position: absolute;
  left: 10px;
  width: 130px;
}
</style>