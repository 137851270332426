<template>
  <div class="main-view">
    <div class="section">
      <div class="filter__wrap">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="showEdit({}, 0)">创建机构</el-button>
        <el-form ref="elFormDom" inline :model="table.params" size="small">
          <el-form-item prop="name">
            <el-input v-model.trim="table.params.name" placeholder="请输入机构名称">
              <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="contact">
            <el-input v-model.trim="table.params.contact" placeholder="请输入负责人">
              <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="account">
            <el-input v-model.trim="table.params.account" placeholder="请输入注册账号">
              <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
            </el-input>
          </el-form-item>
          
        </el-form>
      </div>
      <div style="display: flex;justify-content: space-between;padding-right:24px;">
        <el-radio-group class="radio_group_wrap" v-model="table.params.status" @change="handleTabs" size="medium">
          <el-radio-button :label="1">待审核({{table.total1}})</el-radio-button>
          <el-radio-button :label="2">审核通过({{table.total2}})</el-radio-button>
          <el-radio-button :label="3">审核拒绝({{table.total3}})</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="curField"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:is_freeze="{row}">
        <span :style="{color: row.is_freeze == 0 ? '#27D144' : '#FD4100'}">{{row.is_freeze == 0 ? '正常' : '冻结'}}</span>
      </template>
      
      <template v-slot:action="{row}">
        <!--1-待审核；2-审核通过；3-审核拒绝  -->
        <template v-if="table.params.status == 1">
          <el-button type="text" @click='showEdit(row, table.params.status)'>审核</el-button>
        </template>
        <template v-if="table.params.status == 2">
          <el-button type="text" @click='showSetup(row)'>{{row.rate > 0 ? `抽佣${row.rate}%` : '设置抽佣'}}</el-button>
          <span class="action-btn-line">|</span>
          <el-button type="text" @click='handleOperate(row)'>{{row.is_freeze == 0 ? '冻结' : '恢复' }}</el-button>
          <span class="action-btn-line">|</span>
        </template>
        <template v-if="table.params.status != 1">
          <el-button type="text" @click='showEdit(row, table.params.status)'>查看</el-button>
        </template>
      </template>
    </VTable>

    <edit ref="edit" @refresh="getTable"></edit>
    <setup ref="setup" @refresh="getTable"></setup>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import Setup from './components/Setup.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'OrganList',
  components: {
    VTable,
    Edit,
    Setup,
  },
  mixins: [mixinTable],
  data() {
    return {
      field1: [
        { name: "company_name", label: "机构名称", showTooltip:true, hidden: false },
        { name: "industry", label: "行业类型", hidden: false },
        { name: "contact", label: "负责人", hidden: false},
        { name: "contact_mobile", label: "联系电话", hidden: false},
        { name: "account", label: "注册账号", hidden: false},
        { name: "submit_time", label: "认证时间", width:'140', hidden: false},
        { name: "action", label: "操作", width: "100", hidden: false }
      ],
      field2: [
        { name: "company_name", label: "机构名称", showTooltip:true, hidden: false },
        { name: "industry", label: "行业类型", hidden: false },
        { name: "contact", label: "负责人", hidden: false},
        { name: "contact_mobile", label: "联系电话", hidden: false},
        { name: "account", label: "注册账号", hidden: false},
        { name: "submit_time", label: "认证时间", width:'140', hidden: false},
        { name: "cert_time", label: "通过时间", width:'140', hidden: false},
        { name: "goods_num", label: "产品数量", hidden: false},
        { name: "is_freeze", label: "状态", hidden: false},
        { name: "action", label: "操作", width: "180", hidden: false }
      ],
      field3: [
        { name: "company_name", label: "机构名称", showTooltip:true, hidden: false },
        { name: "industry", label: "行业类型", hidden: false },
        { name: "contact", label: "负责人", hidden: false},
        { name: "contact_mobile", label: "联系电话", hidden: false},
        { name: "account", label: "注册账号", hidden: false},
        { name: "submit_time", label: "认证时间", width:'140', hidden: false},
        { name: "check_time", label: "拒绝时间", width:'140', hidden: false},
        { name: "action", label: "操作", width: "120", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: '',
          contact: '',
          contact: '',
          status: 1,// 1:待审核 2:审核通过 3:审核拒绝
          page: 1, 
          count: 10
        },
        data: [],
        total: 0,
        total1: 0, //待审核
        total2: 0, //审核通过
        total3: 0 //审核拒绝
      },
      curField: '',
    }
  },
  created() {
    this.getTable();
    this.curField = this.field1;
  },
  methods: {
    handleTabs(value) {
      if(value == 1) {
        this.curField = this.field1
      } else if(value == 2) {
        this.curField = this.field2
      } else if(value == 3) {
        this.curField = this.field3
      }
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    // 查询列表
    getTable() {
      this.$http.get('/admin/company/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list
          this.table.total = res.data.total;
          this.table.total1 = res.data.total1;
          this.table.total2 = res.data.total2;
          this.table.total3 = res.data.total3;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row, status) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row, status)
      dom = null
    },
    // 抽佣设置
    showSetup (row) {
      let dom = this.$refs.setup
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 冻结，恢复
    handleOperate(row) {
      let str = row.is_freeze == 0 ? '冻结' : '恢复'
      this.$confirm(`请确认${str}操作？`, "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/admin/company/operation', {company_id: row.id}).then(res => { 
          if(res.code === 1) {
            this.$message.success('操作成功！');
            this.getTable();
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {});
    }
  }
}
</script>

<style scoped lang="scss">
.radio_group_wrap {
  margin:0 18px 18px;

  &::v-deep {
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      color: #0376E7;
      background-color: #E5F2FE;
      border-color: #0376E7;
    }
  }
}
.corlor1 {
  display: flex;
  align-items: center;
}
.corlor2 {
  display: flex;
  align-items: center;
}
.corlor3 {
  display: flex;
  align-items: center;
}
.corlor1::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #FFA10E;
  display: inline-block;
  margin-right: 5px;
}
.corlor2::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #27D144;
  display: inline-block;
  margin-right: 5px;
}
.corlor3::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #EE5050;
  display: inline-block;
  margin-right: 5px;
}


</style>
