<template>
  <el-dialog
    append-to-body
    width="400px"
    title=""
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <template #title>
      <div class="el-dialog-title">设置抽佣</div>
    </template>

    <el-form
      v-loading="loading"
      class="saft_form"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="130px"
      label-position="top"
      :model="form.data"
      :rules="form.rules"
      size="medium"
      hide-required-asterisk
    >
      <el-form-item prop="rate" label="抽佣比例">
        <el-input
          v-model="form.data.rate"
          placeholder="请输入数字 最多一个小数点"
        >
        <span slot="suffix">%</span>
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确定</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { REG_EXP } from '@/util'
  export default {
    name: 'OrganSetup',
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            company_id: '',
            rate: '',
          },
          rules: {
            rate: [
              {required: true, message: "必填项", trigger: "change"},
              {pattern: REG_EXP.setup, message: "只能输入数字 最多一个小数点", trigger: "blur"}
            ],
          }
        },
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.form.data.company_id = row.id;
        this.form.data.rate = row.rate;
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.loading = true;
            let _params = {
              company_id: this.form.data.company_id,
              rate: this.form.data.rate,
            }
            this.$http.post('/admin/company/setRate', _params).then(res => {
              if(res.code === 1) {
                this.$message.success('操作成功！')
                this.toggle(false)
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
           }).finally(() => {
             this.form.loading = false;
           })
          }
        })
      },
    }
  }
</script>